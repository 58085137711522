import React, { FC } from 'react';
import './TechnicalSummary.scss';
import SectionTitle from '../../../components/common/SectionTitle';
import ListItem from '../../../components/pagesComponents/CaseStudy/ListItem';
import Span from '../../../components/pagesComponents/CaseStudy/Span';
import Links from '../../../components/pagesComponents/CaseStudy/Links';
import StatfloLayout from '../../../components/PagesLayouts/StatfloLayout';
import FixedButton from '../../../components/common/FixedButton';

const TechnicalSummary: FC = () => {
    return (
        <StatfloLayout>
            <div className="statflo-case-study__text statflo-case-study__text--large">
                <SectionTitle type="primary" title="Technical summary" />
                <ListItem margin="mt">
                    In the early beginning of our cooperation with the client, Blackbird worked on
                    multiple microservices where we used Docker and Kubernetes for deployment. The
                    communication between the microservices was done via RabbitMQ.
                </ListItem>
                <ListItem>
                    Built the first version of the new 1:1 Retail Conversations™ utilizing AWS
                    Amplify framework (as a part of the<Span type="bold">Research</Span>and
                    <Span type="bold">Analytics</Span>services used).
                </ListItem>
                <ListItem>
                    Migrated architecture from entirely microservice-based application architecture
                    to CQRS with event sourcing. Utilized
                    <Span type="bold">Axon</Span>Framework to support the new application
                    infrastructure.
                </ListItem>
                <ListItem>
                    Senior developers mastered and successfully applied Command Query Responsibility
                    Segregation (CQRS) architectural pattern with event sourcing within two calendar
                    months.
                </ListItem>
                <ListItem>
                    Overcame<Span type="bold">Twilio API</Span>limitations of 1000 threads per
                    account alongside learned how to reassigning each of threads to sales reps under
                    multiple accounts.
                </ListItem>
                <ListItem>Improved DNC handling both auto and manual ones.</ListItem>
                <ListItem>
                    Developed the new brand, legal and privacy Compliance API for all of Statflo
                    products.
                </ListItem>
                <ListItem>
                    Moved from own SSO authentication solution to
                    <Span type="bold">Auth0 API.</Span>
                </ListItem>
                <ListItem>
                    Implemented the new responsive UI design on the front end with <span>React</span>,
                    <span> Typescript</span>,
                    <span> Redux Thunk</span>,
                    <span> Redux Toolkit</span>. Apollo Client was used since all powered by the
                    <Span type="bold">Apollo Graphql.</Span>
                </ListItem>
                <ListItem>
                    <span>Quality control: </span>
                    all endpoints are covered with end-to-end automation tests in Postman.
                </ListItem>
                <ListItem>
                    Deployed and operated DevOps infrastructure for the product to run a closed beta
                    with the most interested in product clients.
                </ListItem>
                <Links />
            </div>
            <FixedButton/>
        </StatfloLayout>
    );
};

export default TechnicalSummary;
